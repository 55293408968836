import jsPDF from "jspdf";
import "jspdf-autotable";
import { addHeaders, addFooters, marges2 } from "print/entete.js";
import { numberWithSpace,datefr} from "@/utils/functions.js";
import { entites,monobl } from "print/data.js";
let mvm_type=0
  export  function livraison (store,list_mvm,data_list,data_item) {
    let  entite_name = "";
   // let  entite_ville = "";
    
    let f=entites.findIndex(elm=>elm.id==1);
    if (f>=0){
      entite_name =entites[f].name;
   //   entite_ville =entites[f].ville_name;
    }
    let item;
    //data formating
let blheaders= [
  {
    text: "No",
    value: "no",
    selected: true,
    align: "center",
    width: 10,
  },
  {
    text: "Ref.",
    value: "code",
    selected: true,
    align: "left",
    width: "auto",
  },  
  {
    text: "Désignation",
    value: "label",
    selected: true,
    align: "left",
    width: "auto",
  },  
  {
    text: "Quantité",
    value: "qte",
    slot: "cur3",
    width: 30,
    align: "end",
    selected: true,
  },
]

var doc = new jsPDF("p");
  let marges = marges2(doc,store);
  let items = [];
  
    for (let index = 0; index < data_list.length; index++) {
      item = {};
      item.mvm_id=data_list[index].mvm_id
      
      blheaders.forEach((elm) => {
        if (elm.value == "no") {
          item[elm.value] = index + 1;
        }
        if (elm.slot == "cur22") {
          item[elm.value] = numberWithSpace(
            parseFloat(data_list[index][elm.value]).toFixed(2)
          );
        } else if (elm.slot == "cur" && data_list[index][elm.value]) {
          item[elm.value] = numberWithSpace(
            parseFloat(data_list[index][elm.value]).toFixed(2)
          );
        } else if (elm.slot == "cur3" && data_list[index][elm.value]) {
          item[elm.value] = numberWithSpace(
            parseFloat(data_list[index][elm.value]).toFixed(3)
          );
        } else if (data_list[index][elm.value])
          item[elm.value] = data_list[index][elm.value];
      });    
      item.label = data_list[index].label +
      (data_list[index].description ? "\n" + data_list[index].description : "");
      items.push(item);
    }      
    let i=0
    let m=1
    if (monobl==false) {
      m=list_mvm.length
      if (data_item.nomvm!=null) m++
    } 
   
while (i < m) {
  if (i>0) doc.addPage();
    let columnStyles = {};
    let id,mvm_list
    if ((data_item.nomvm!=null && i==m-1) || monobl) {
      id=null     
      mvm_list={nomvm:data_item.nomvm,opr_date:data_item.fact_date,mvm_type:3,cmds_ref:data_item.cmds_ref}
    }
    else {
      id=list_mvm[i].id
      mvm_list=list_mvm[i]
    }
     let bl_items=monobl ? items : items.filter(elm=>elm.mvm_id==id)
         
     let tier = [];
     tier.push({
       lib: {
         content: "Client : ",
         styles: { fontStyle: "normal", fontSize: 12, textColor: [0, 0, 0] },
         colSpan: 2,
       },
     });
     tier.push({
       lib: {
         content: data_item.tier_name,
         styles: { fontStyle: "bold", fontSize: 12, textColor: [0, 0, 0] },
         colSpan: 2,
       },
     });       
     tier.push({
       lib: {
         content: "Adresse :",
         styles: { fontStyle: "normal", fontSize: 9 },
       },
       val: {
         content: data_item.adresse ? data_item.adresse : "",
         styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
       },
     });
     tier.push({
       lib: {
         content: "Tel :",
         styles: { fontStyle: "normal", fontSize: 9 },
       },
       val: {
         content: data_item.tel ? data_item.tel : "",
         styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
       },
     });
     tier.push({
       lib: {
         content: "Fax : ",
         styles: { fontStyle: "normal", fontSize: 9 },
       },
       val: {
         content: data_item.fax ? data_item.fax : "",
         styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
       },
     });
     tier.push({
      lib: {
        content: "Email : ",
        styles: { fontStyle: "normal", fontSize: 9 },
      },
      val: {
        content: data_item.email ? data_item.email : "",
        styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
      },
    });
    if (mvm_type == 3)
    tier.push({
      lib: {
        content: "Site de Livraison : ",
        styles: { fontStyle: "normal", fontSize: 9 },
      },
      val: {
        content: mvm_list.adresse_liv ? mvm_list.adresse_liv : "",
        styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
      },
    });
    
      mvm_type= mvm_list.mvm_type

      let doctype = [];
      doctype.push({
        lib: {
          content: 
          mvm_type == 3
        ? "Bon de Livraison"      
        : mvm_type == 23
        ? "Service Fait"      
        :"Bon de Retour",
          styles: { fontStyle: "bold", fontSize: 20, textColor: [0, 0, 0] },
          colSpan: 2,
        },
      });
      doctype.push({
        lib: {
          content: "N° :",
          styles: { fontStyle: "normal", fontSize: 10 },
        },
        val: {
          content: mvm_list.nomvm ? mvm_list.nomvm : mvm_list.nobon,
          styles: { fontStyle: "normal", fontSize: 10, textColor: [0, 0, 0] },
        },
      });
      doctype.push({
        lib: {
          content: "Date : ",
          styles: { fontStyle: "normal", fontSize: 10 },
        },
        val: {
          content: datefr(mvm_list.opr_date),
          styles: { fontStyle: "normal", fontSize: 10, textColor: [0, 0, 0] },
        },
      });
      doctype.push({
        lib: {
          content: "Commandes Ref.:",
          styles: { fontStyle: "normal", fontSize: 9 },
        },
        val: {
          content: mvm_list.cmds_ref ? mvm_list.cmds_ref : "",
          styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
        },
      });
      // doctype.push({
      //   lib: {
      //     content: "Commande N° : ",
      //     styles: { fontStyle: "normal", fontSize: 9 },
      //   },
      //   val: {
      //     content: mvm_list.nocmd ? mvm_list.nocmd : "",
      //     styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
      //   },
      // });
      // doctype.push({
      //   lib: {
      //     content: "Du : ",
      //     styles: { fontStyle: "normal", fontSize: 9 },
      //   },
      //   val: {
      //     content: datefr(mvm_list.date_cmd),
      //     styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
      //   },
      // });
   
      columns = [];
      columns.push({ dataKey: "lib" });
      columns.push({ dataKey: "val" });
     
      doc.autoTable({
        margin: { left: marges.margin_left },
        startY:marges.margin_top + marges.l_h+8, 
        body: tier,
        columnStyles: {
          0: { halign: "left", cellWidth: 25 },
          1: { halign: "left", cellWidth: "auto" },     
        },
        columns: columns,
        theme: "plain",
        styles: {
          valign: "top",
          fillColor: [255, 255, 255],
          textColor: [128, 128, 128],
          cellPadding: 0,
        },
        tableWidth: 80,
      });
      let posmax=doc.lastAutoTable.finalY
      doc.autoTable({
        margin: { left: marges.margin_left+95 , },
        startY:marges.margin_top + marges.l_h, 
        body: doctype,
        
        columnStyles: {
          0: { halign: "left", cellWidth: 50 },
          1: { halign: "left", cellWidth: "auto" },     
        },
        columns: columns,
        theme: "plain",
        styles: {
          valign: "top",
          fillColor: [255, 255, 255],
          textColor: [128, 128, 128],
          cellPadding: 0,
        },
        tableWidth: "auto",
      });
      if (doc.lastAutoTable.finalY>posmax) posmax=doc.lastAutoTable.finalY  
   
      item = {};
      let head = [];
      let columns = [];
      blheaders.forEach((element) => {
        head.push({
          content: element.text,
          styles: {
            halign:
              element.align == "end"
                ? "right"
                : element.align == "center"
                ? "center"
                : "left",
          },
        });
        
        columns.push({ dataKey: element.value });
        columnStyles[element.value] = {
          cellWidth: element.width,
          valign: "top",
          halign:
            element.align == "end"
              ? "right"
              : element.align == "center"
              ? "center"
              : "left",
        };
      });
   
      doc.autoTable({
        margin: {  bottom: marges.margin_bottom + marges.p_h },
        startY:posmax + 5,   
        didDrawPage: function(data) {
          // Reseting top margin. The change will be reflected only after print the first page.
          data.settings.margin.top = marges.margin_top + marges.l_h + 10;
        },
        headStyles: {
          halign: "center",
          valign: "middle",
          fillColor: [128, 128, 128],
        },
        columnStyles: columnStyles,
        head: [head],
        body: bl_items,
        columns: columns,
        showHead: "everyPage",
        showFoot: "everyPage",
        theme: "striped",
      });
      // let foot = [];         
      // foot.push({
      //   libelle: "Montant HT",
      //   value: numberWithSpace(mvm_list.mont_ht.toFixed(2)),
      // });
      // foot.push({
      //   libelle: "TVA",
      //   value: numberWithSpace(mvm_list.mont_tva.toFixed(2)),
      // });
      // foot.push({
      //   libelle: "Montant TTC",
      //   value: numberWithSpace(mvm_list.mont_ttc.toFixed(2)),
      // });
      // columns = [];
      // columns.push({ dataKey: "libelle" });
      // columns.push({ dataKey: "value" });
      // doc.autoTable({
      //   margin: { left: marges.margin_left + 95 },
      //   body: foot,
      //   columnStyles: {
      //     0: { halign: "left", cellWidth: "auto", fontSize: 10 },
      //     1: {
      //       halign: "right",
      //       cellWidth: 30,
      //       fontSize: 10,
      //     },
      //   },
      //   columns: columns,
      //   theme: "plain",
      //   styles: {
      //     valign: "middle",
      //     fontSize: 12,
      //     fillColor: [255, 255, 255],
      //     textColor: [0, 0, 0],
      //     cellPadding: {right:2,bottom:0.5},
      //   },
      //   tableWidth: "auto",
      // });
   
    
      doc.setFontSize(10);
      doc.setFont("helvetica", "normal");

      if (mvm_type == 3)
      doc.autoTable({
        startY: doc.lastAutoTable.finalY + 15,
        head: [["Pour " + entite_name, "Pour " + data_item.tier_name]],
        columnStyles: {
          0: {
            cellWidth:
              (doc.internal.pageSize.width - marges.margin_left - marges.margin_right) / 2,
          },
          1: { cellWidth: "auto" },
        },
        theme: "plain",
        headStyles: {
          halign: "left",
          valign: "middle",
        },
        tableWidth: doc.internal.pageSize.width - marges.margin_left - marges.margin_right,
      });
    i++
}
addHeaders(doc, store,null,null,1);
addFooters(doc, store);
      doc.setProperties({ title: mvm_type == 3
        ? "Bon de Livraison"      
        : mvm_type == 23
        ? "Service Fait"      
        :"Bon de Retour" });
      doc.output("pdfobjectnewwindow");

}