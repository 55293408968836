import jsPDF from "jspdf";
import "jspdf-autotable";
import { addHeaders, addFooters, marges2 } from "print/entete.js";
import { numberWithSpace,datefr} from "@/utils/functions.js";

let mvm_type=0
  export  function reception (store,mvm_list,data_list,data_item) {   
  
    let item;

let rcptheaders= [
  {
    text: "No",
    value: "no",
    selected: true,
    align: "center",
    width: 10,
  },
  {
    text: "Ref.",
    value: "code",
    selected: true,
    align: "left",
    width: 20,
  },
  {
    text: "Désignation",
    value: "product_name",
    selected: true,
    align: "left",
    width: "auto",
  },
  {
    text: "Quantité",
    value: "qte",
    slot: "cur3",
    width: 30,
    align: "end",
    selected: true,
  },
  {
    text: "PU HT",
    value: "pu",     
    width: 25,
    align: "end",
    selected: true,
  },
  {
    text: "Montant HT",
    value: "ht",
    slot: "cur",
    width: 30,
    align: "end",
    selected: true,
  },
  {
    text: "Tx TVA",
    value: "tva_tx",
    slot: "cur",
    width: 20,
    align: "end",
    selected: true,
  },
  {
    text: "Observation",
    value: "comment",
    width: 70,
    selected: true,
  }
]

var doc = new jsPDF( "l");
  let marges = marges2(doc,store);
  let items = [];
  
    for (let index = 0; index < data_list.length; index++) {
      item = {};
      item.mvm_id=data_list[index].mvm_id
      
      rcptheaders.forEach((elm) => {
        if (elm.value == "no") {
          item[elm.value] = index + 1;
        }
        if (elm.slot == "cur22") {
          item[elm.value] = numberWithSpace(
            parseFloat(data_list[index][elm.value]).toFixed(2)
          );
        } else if (elm.slot == "cur" && data_list[index][elm.value]) {
          item[elm.value] = numberWithSpace(
            parseFloat(data_list[index][elm.value]).toFixed(2)
          );
        } else if (elm.slot == "cur3" && data_list[index][elm.value]) {
          item[elm.value] = numberWithSpace(
            parseFloat(data_list[index][elm.value]).toFixed(3)
          );
        } else if (data_list[index][elm.value])
          item[elm.value] = data_list[index][elm.value];
      });
      items.push(item);
    }    
    let i=0
while (i < mvm_list.length) {
  if (i>0) doc.addPage();
    let columnStyles = {}; 
     let bl_items=items.filter(elm=>elm.mvm_id==mvm_list[i].id)
         
     let tier = [];
     tier.push({
       lib: {
         content: mvm_type == 1 || mvm_type == 17 ? "Fournisseur : " : "Client" ,
         styles: { fontStyle: "normal", fontSize: 12, textColor: [0, 0, 0] },
         colSpan: 2,
       },
     });
     tier.push({
       lib: {
         content: data_item.tier_name,
         styles: { fontStyle: "bold", fontSize: 12, textColor: [0, 0, 0] },
         colSpan: 2,
       },
     });       
     tier.push({
       lib: {
         content: "Adresse :",
         styles: { fontStyle: "normal", fontSize: 9 },
       },
       val: {
         content: data_item.adresse ? data_item.adresse : "",
         styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
       },
     });
     tier.push({
       lib: {
         content: "Tel :",
         styles: { fontStyle: "normal", fontSize: 9 },
       },
       val: {
         content: data_item.tel ? data_item.tel : "",
         styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
       },
     });
     tier.push({
       lib: {
         content: "Fax : ",
         styles: { fontStyle: "normal", fontSize: 9 },
       },
       val: {
         content: data_item.fax ? data_item.fax : "",
         styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
       },
     });
     tier.push({
      lib: {
        content: "Email : ",
        styles: { fontStyle: "normal", fontSize: 9 },
      },
      val: {
        content: data_item.email ? data_item.email : "",
        styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
      },
    });  
  
      mvm_type= mvm_list[i].mvm_type

      let doctype = [];
      doctype.push({
        lib: {
          content: mvm_type == 1
          ? "Bon de Reception"
          : mvm_type == 17
            ? "ATTESTATION DE SERVICE FAIT"
          : "Bon de Retour" ,
          styles: { fontStyle: "bold", fontSize: mvm_type == 17 ? 14 : 20, textColor: [0, 0, 0] },
          colSpan: 2,
        },
      });
      doctype.push({
        lib: {
          content: "N° :",
          styles: { fontStyle: "normal", fontSize: 10 },
        },
        val: {
          content: mvm_list[i].nomvm ? mvm_list[i].nomvm : mvm_list[i].nobon,
          styles: { fontStyle: "normal", fontSize: 10, textColor: [0, 0, 0] },
        },
      });
      doctype.push({
        lib: {
          content: "Date : ",
          styles: { fontStyle: "normal", fontSize: 10 },
        },
        val: {
          content: datefr(mvm_list[i].mvm_date),
          styles: { fontStyle: "normal", fontSize: 10, textColor: [0, 0, 0] },
        },
      });
      // doctype.push({
      //   lib: {
      //     content: "Commande N° : ",
      //     styles: { fontStyle: "normal", fontSize: 9 },
      //   },
      //   val: {
      //     content: mvm_list[i].nocmd ? mvm_list[i].nocmd : "",
      //     styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
      //   },
      // });
      // doctype.push({
      //   lib: {
      //     content: "Du : ",
      //     styles: { fontStyle: "normal", fontSize: 9 },
      //   },
      //   val: {
      //     content: datefr(mvm_list[i].date_cmd),
      //     styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
      //   },
      // });
    
      columns = [];
      columns.push({ dataKey: "lib" });
      columns.push({ dataKey: "val" });
     
      doc.autoTable({
        margin: { left: marges.margin_left },
        startY:marges.margin_top + marges.l_h+8, 
        body: tier,
        columnStyles: {
          0: { halign: "left", cellWidth: 25 },
          1: { halign: "left", cellWidth: "auto" },     
        },
        columns: columns,
        theme: "plain",
        styles: {
          valign: "middle",
          fillColor: [255, 255, 255],
          textColor: [128, 128, 128],
          cellPadding: 0,
        },
        tableWidth: 80,
      });
      let posmax=doc.lastAutoTable.finalY
      doc.autoTable({
        margin: { left: marges.margin_left+95 , },
        startY:marges.margin_top + marges.l_h, 
        body: doctype,
        
        columnStyles: {
          0: { halign: "left", cellWidth: 50 },
          1: { halign: "left", cellWidth: "auto" },     
        },
        columns: columns,
        theme: "plain",
        styles: {
          valign: "middle",
          fillColor: [255, 255, 255],
          textColor: [128, 128, 128],
          cellPadding: 0,
        },
        tableWidth: "auto",
      });
      if (doc.lastAutoTable.finalY>posmax) posmax=doc.lastAutoTable.finalY  
   
      let lettre = [];
      if (mvm_type == 17) {                     
        let t=[]
        columns = [];
          columns.push({ dataKey: "lib" });
          t.push({
            lib: {
              content:  "Nous Soussigné, Monsieur : " +
              (mvm_list[i].preneur ? mvm_list[i].preneur : "") +
              " agissant en qualité de : " +
              (mvm_list[i].fonction ? mvm_list[i].fonction : "") +
              " avons vérifié les articles ayant fait l'objet de commande ci-dessus selon le detail suivant : ",
              styles: { fontStyle: "normal", fontSize: 10, textColor: [0, 0, 0] },     
            },
          });
          doc.autoTable({
            margin: { left: marges.margin_left+15 },
            startY:posmax + 5,    
            body: t,
            columnStyles: {      
              0: { halign: "left", cellWidth: "auto" },     
            },
            columns: columns,
            theme: "plain",
            styles: {
              valign: "middle",
              fillColor: [255, 255, 255],
              textColor: [128, 128, 128],
              cellPadding: 0,
            },
            tableWidth: "auto",
          });
          if (doc.lastAutoTable.finalY>posmax) posmax=doc.lastAutoTable.finalY
          lettre.push({
            libelle: {
              content:
                "Et avons constaté que les travaux ont été réellement fidèlement exécutés ",
              styles: { fontStyle: "normal", fontSize: 10 },
            },
          });
          lettre.push({
            libelle: {
              content: "En foi de quoi, nous signons cette attestation. ",
              styles: { fontStyle: "normal", fontSize: 10 },
            },
          });
      }
     
      item = {};
      let head = [];
      let columns = [];
      rcptheaders.forEach((element) => {
        head.push({
          content: element.text,
          styles: {
            halign:
              element.align == "end"
                ? "right"
                : element.align == "center"
                ? "center"
                : "left",
          },
        });
        
        columns.push({ dataKey: element.value });
        columnStyles[element.value] = {
          cellWidth: element.width,
          valign: "middle",
          halign:
            element.align == "end"
              ? "right"
              : element.align == "center"
              ? "center"
              : "left",
        };
      });
   
      doc.autoTable({
        margin: {  bottom: marges.margin_bottom + marges.p_h },
        startY:posmax + 5,   
        didDrawPage: function(data) {
          // Reseting top margin. The change will be reflected only after print the first page.
          data.settings.margin.top = marges.margin_top + marges.l_h + 10;
        },
        headStyles: {
          halign: "center",
          valign: "middle",
          fillColor: [128, 128, 128],
        },
        columnStyles: columnStyles,
        head: [head],
        body: bl_items,
        columns: columns,
        showHead: "everyPage",
        showFoot: "everyPage",
        theme: "striped",
      });
      // let foot = [];         
      // foot.push({
      //   libelle: "Montant HT",
      //   value: numberWithSpace(mvm_list[i].mont_ht.toFixed(2)),
      // });
      // foot.push({
      //   libelle: "TVA",
      //   value: numberWithSpace(mvm_list[i].mont_tva.toFixed(2)),
      // });
      // foot.push({
      //   libelle: "Montant TTC",
      //   value: numberWithSpace(mvm_list[i].mont_ttc.toFixed(2)),
      // });
      // columns = [];
      // columns.push({ dataKey: "libelle" });
      // columns.push({ dataKey: "value" });
      // doc.autoTable({
      //   margin: { left: marges.margin_left + 95 },
      //   body: foot,
      //   columnStyles: {
      //     0: { halign: "left", cellWidth: "auto", fontSize: 10 },
      //     1: {
      //       halign: "right",
      //       cellWidth: 30,
      //       fontSize: 10,
      //     },
      //   },
      //   columns: columns,
      //   theme: "plain",
      //   styles: {
      //     valign: "middle",
      //     fontSize: 12,
      //     fillColor: [255, 255, 255],
      //     textColor: [0, 0, 0],
      //     cellPadding: {right:2,bottom:0.5},
      //   },
      //   tableWidth: "auto",
      // });
   
    
     
      doc.setFontSize(10);
      doc.setFont("helvetica", "normal");

    
      doc.autoTable({
        margin: { left: marges.margin_left },
        body: lettre,
        columnStyles: {
          0: { halign: "left", cellWidth: "auto" },         
        },
        columns: columns,
        theme: "plain",
        styles: {
          valign: "middle",
          fontSize: 11,
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          cellPadding: 0.5,        
        },
        tableWidth: "auto",
      });
    i++
}
addHeaders(doc, store,null,null,1);
addFooters(doc, store);
      doc.setProperties({ title:   mvm_type == 1
        ? "Bon de Reception"
        : mvm_type == 17
          ? "ATTESTATION DE SERVICE FAIT"
        : "Bon de Retour"  });
      doc.output("pdfobjectnewwindow");

}